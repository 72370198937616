/* global module, require */

var iurio = iurio || {}; //eslint-disable-line
iurio.crypto = {};

if (typeof require !== 'undefined') {
	const { subtle } = require('node:crypto').webcrypto;
	const store = new Map();
	const storage = {
		setItem: function (key, value) {
			store.set(key, value);
		},
		getItem: function (key) {
			return store.get(key);
		},
		removeItem: function (key) {
			store.delete(key);
		},
	};
	// eslint-disable-next-line no-redeclare
	var window = {
		crypto: {
			subtle,
		},
		localStorage: storage,
		localforage: storage,
	};

	const bcrypt = require('bcryptjs');
	var dcodeIO = { bcrypt };
	iurio.utils = require('./utils.js');
}

/**
 * Whether Web crypto API is supported
 *
 * @return {boolean}
 */
iurio.crypto.isSupported = function () {
	return !!(window.crypto && window.crypto.subtle);
};

iurio.crypto.constants = {
	asymmetricEncryptionAlgorithm: 'RSA-OAEP',
	asymmetricKeySize: 4096,
	asymmetricPublicExponent: new Uint8Array([1, 0, 1]),
	hashAlgorithm: 'SHA-256',
	keyDerivationAlgorithm: 'PBKDF2',
	keyDerivationIterations: 10000,
	keyDerivationHashAlgorithm: 'SHA-512',
	keyWrappingAsymmetricAlgorithm: 'RSA-OAEP',
	keyWrappingSymmetricAlgorithm: 'AES-KW',
	symmetricEncryptionAlgorithm: 'AES-GCM',
	symmetricKeySize: 256,
	symmetricIVByteSize: 12,
	symmetricTagLength: 128,
	bcryptCost: 12,
};

iurio.crypto.utils = {
	arrayBufferToString: function (buffer) {
		return this.textDecoder.decode(buffer);
	},

	arrayBufferToB64String: function (buffer) {
		return iurio.utils.base64utils.bytesToBase64(new Uint8Array(buffer));
	},

	arrayBufferToHexString: function (buffer) {
		return Array.prototype.map.call(new Uint8Array(buffer), byte => byte.toString(16).padStart(2, '0')).join('');
	},

	stringToArrayBuffer: function (str) {
		return this.textEncoder.encode(str);
	},

	b64StringToArrayBuffer: function (str) {
		const typedArray = iurio.utils.base64utils.base64ToBytes(str);
		return typedArray.buffer.slice(typedArray.byteOffset, typedArray.byteOffset + typedArray.length);
	},

	hexStringToArrayBuffer: function (str) {
		return new Uint8Array(str.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
	},

	textEncoder: new TextEncoder(),
	textDecoder: new TextDecoder(),
};

iurio.crypto.primitives = {
	/**
	 * Generate a symmetric key
	 *
	 * @return {Promise<CryptoKey>}
	 */
	generateSymmetricKey: async function () {
		const algo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			length: iurio.crypto.constants.symmetricKeySize,
		};
		return window.crypto.subtle.generateKey(algo, true, ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']);
	},

	/**
	 * Generate a symmetric key used to (un-)wrap a key
	 *
	 * @return {Promise<CryptoKey>}
	 */
	generateSymmetricWrappingKey: async function () {
		const algo = {
			name: iurio.crypto.constants.keyWrappingSymmetricAlgorithm,
			length: iurio.crypto.constants.symmetricKeySize,
		};
		return window.crypto.subtle.generateKey(algo, true, ['wrapKey', 'unwrapKey']);
	},

	/**
	 * Derives a symmetric key from a password to en/decrypt data
	 *
	 * @param {string} password
	 * @param {ArrayBuffer} salt
	 * @param {number} [iterations]
	 * @return {Promise<CryptoKey>}
	 */
	deriveSymmetricKey: async function (password, salt, iterations) {
		let algo = {
			name: iurio.crypto.constants.keyDerivationAlgorithm,
		};
		const keyMaterial = await window.crypto.subtle.importKey('raw', iurio.crypto.utils.textEncoder.encode(password), algo, false, ['deriveBits', 'deriveKey']);
		algo.salt = salt;
		algo.iterations = typeof iterations === 'undefined' ? iurio.crypto.constants.keyDerivationIterations : iterations;
		algo.hash = iurio.crypto.constants.keyDerivationHashAlgorithm;
		const keyAlgo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			length: iurio.crypto.constants.symmetricKeySize,
		};
		return window.crypto.subtle.deriveKey(algo, keyMaterial, keyAlgo, false, ['encrypt', 'decrypt']);
	},

	/**
	 * Derives a symmetric key from a password to (un-)wrap a key
	 *
	 * @param {(string|ArrayBuffer)} password
	 * @param {ArrayBuffer} salt
	 * @param {number} [iterations]
	 * @return {Promise<CryptoKey>}
	 */
	deriveSymmetricWrappingKey: async function (password, salt, iterations) {
		const algo = {
			name: iurio.crypto.constants.keyDerivationAlgorithm,
		};
		const data = typeof password === 'string' ? iurio.crypto.utils.textEncoder.encode(password) : password;
		const keyMaterial = await window.crypto.subtle.importKey('raw', data, algo, false, ['deriveBits', 'deriveKey']);
		algo.salt = salt;
		algo.iterations = typeof iterations === 'undefined' ? iurio.crypto.constants.keyDerivationIterations : iterations;
		algo.hash = iurio.crypto.constants.keyDerivationHashAlgorithm;
		const keyAlgo = {
			name: iurio.crypto.constants.keyWrappingSymmetricAlgorithm,
			length: iurio.crypto.constants.symmetricKeySize,
		};
		return window.crypto.subtle.deriveKey(algo, keyMaterial, keyAlgo, false, ['wrapKey', 'unwrapKey']);
	},

	/**
	 * Derive a symmetric key from the raw data of an exportable CryptoKey using HKDF
	 *
	 * @param {CryptoKey} key
	 * @param {BufferSource} salt
	 * @return {Promise<CryptoKey>}
	 */
	deriveSymmetricKeyFromAsymmetric: async function (key, salt) {
		const bits = await window.crypto.subtle.exportKey('pkcs8', key);
		const source = await window.crypto.subtle.importKey('raw', bits, 'HKDF', false, ['deriveKey']);
		const info = new ArrayBuffer(0);
		const algo = {
			name: 'HKDF',
			hash: iurio.crypto.constants.hashAlgorithm,
			salt,
			info,
		};
		const keyAlgo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			length: iurio.crypto.constants.symmetricKeySize,
		};
		return window.crypto.subtle.deriveKey(algo, source, keyAlgo, false, ['encrypt', 'decrypt']);
	},

	/**
	 * Exports a key
	 *
	 * @param {CryptoKey} key
	 * @return {Promise<Object>} key in jwk format
	 */
	exportKey: async function (key) {
		return window.crypto.subtle.exportKey('jwk', key);
	},

	/**
	 * Exports a key as JSON string
	 *
	 * @param {CryptoKey} key
	 * @return {Promise<string>} JSON encoded key in jwk format
	 */
	exportKeyJSON: async function (key) {
		let jwk = await this.exportKey(key);
		let jwkString = JSON.stringify(jwk);
		return jwkString;
	},

	/**
	 * Exports a symmetric key as a string
	 *
	 * @param {CryptoKey} key
	 * @return {Promise<string>} symmetric key data
	 */
	exportSymmetricKeyString: async function (key) {
		let jwk = await this.exportKey(key);
		return jwk.k;
	},

	/**
	 * Imports a symmetric key
	 *
	 * @param {Object} jwk
	 * @return {Promise<CryptoKey>}
	 */
	importSymmetricKey: async function (jwk) {
		const keyAlgo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
		};
		return window.crypto.subtle.importKey('jwk', jwk, keyAlgo, true, ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']);
	},

	/**
	 * Imports a symmetric key from a JSON string
	 *
	 * @param {string} jwkString
	 * @return {Promise<CryptoKey>}
	 */
	importSymmetricKeyJSON: async function (jwkString) {
		let jwk = JSON.parse(jwkString);
		return this.importSymmetricKey(jwk);
	},

	/**
	 * Imports a symmetric key from a string
	 *
	 * @param {string} kString
	 * @return {Promise<CryptoKey>}
	 */
	importSymmetricKeyString: async function (kString) {
		const jwk = {
			alg: 'A256GCM',
			ext: true,
			k: kString,
			'key_ops': ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'],
			kty: 'oct',
		};
		return this.importSymmetricKey(jwk);
	},

	/**
	 * Imports a symmetric key used to (un-)wrap a key from a string
	 *
	 * @param {string} kString
	 * @return {Promise<CryptoKey>}
	 */
	importSymmetricWrappingKeyString: async function (kString) {
		const jwk = {
			alg: 'A256KW',
			ext: false,
			k: kString,
			'key_ops': ['unwrapKey'],
			kty: 'oct',
		};
		const keyAlgo = {
			name: iurio.crypto.constants.keyWrappingSymmetricAlgorithm,
		};
		return window.crypto.subtle.importKey('jwk', jwk, keyAlgo, false, ['unwrapKey']);
	},

	/**
	 * Imports a private key as JSON string
	 *
	 * @param {string} jwkString
	 * @return {Promise<CryptoKey>}
	 */
	importPrivateKeyJSON: async function (jwkString) {
		let jwk = JSON.parse(jwkString);
		const keyAlgo = {
			name: iurio.crypto.constants.keyWrappingAsymmetricAlgorithm,
			hash: iurio.crypto.constants.hashAlgorithm,
		};
		return window.crypto.subtle.importKey('jwk', jwk, keyAlgo, true, ['unwrapKey']);
	},

	/**
	 * Imports a public key as JSON string
	 *
	 * @param {string} jwkString
	 * @return {Promise<CryptoKey>}
	 */
	importPublicKeyJSON: async function (jwkString) {
		let jwk = JSON.parse(jwkString);
		const keyAlgo = {
			name: iurio.crypto.constants.keyWrappingAsymmetricAlgorithm,
			hash: iurio.crypto.constants.hashAlgorithm,
		};
		return window.crypto.subtle.importKey('jwk', jwk, keyAlgo, false, ['wrapKey']);
	},

	/**
	 * Generate an asymmetric keypair
	 *
	 * @return {Promise<CryptoKeyPair>}
	 */
	generateKeyPair: async function () {
		const algo = {
			name: iurio.crypto.constants.asymmetricEncryptionAlgorithm,
			modulusLength: iurio.crypto.constants.asymmetricKeySize,
			publicExponent: iurio.crypto.constants.asymmetricPublicExponent,
			hash: iurio.crypto.constants.hashAlgorithm,
		};
		return window.crypto.subtle.generateKey(algo, true, ['wrapKey', 'unwrapKey']);
	},

	/**
	 * Returns the Modulus of the given public key
	 *
	 * @param {CryptoKey} publicKey
	 * @return {Promise<ArrayBuffer>}
	 */
	exportPublicKeyMod: async function (publicKey) {
		const jwk = await window.crypto.subtle.exportKey('jwk', publicKey);

		const mod = iurio.utils.base64urlToBase64(jwk.n);
		return iurio.crypto.utils.b64StringToArrayBuffer(mod);
	},

	/**
	 * Imports the given modulus (base64 encoded) as a public key
	 *
	 * @param {string} modulus
	 * @return {Promise<CryptoKey>}
	 */
	importPublicKeyMod: async function (modulus) {
		modulus = iurio.utils.base64ToBase64url(modulus);

		const exp = iurio.crypto.utils.arrayBufferToB64String(iurio.crypto.constants.asymmetricPublicExponent);
		const jwk = {
			alg: iurio.crypto.constants.asymmetricEncryptionAlgorithm + iurio.crypto.constants.hashAlgorithm.substring(3),
			e: exp,
			ext: true,
			'key_ops': ['wrapKey'],
			kty: iurio.crypto.constants.asymmetricEncryptionAlgorithm.substring(0, 3),
			n: modulus,
		};

		const algo = {
			name: iurio.crypto.constants.asymmetricEncryptionAlgorithm,
			hash: iurio.crypto.constants.hashAlgorithm,
		};

		return window.crypto.subtle.importKey('jwk', jwk, algo, true, ['wrapKey']);
	},

	/**
	 * Wrap a private key with a symmetric key
	 *
	 * @param {CryptoKey} privKey
	 * @param {CryptoKey} wrappingKey
	 * @return {Promise<ArrayBuffer>}
	 */
	wrapPrivateKeyWithSymmetricKey: async function (privKey, wrappingKey) {
		const algo = {
			name: iurio.crypto.constants.keyWrappingSymmetricAlgorithm,
		};
		return window.crypto.subtle.wrapKey('jwk', privKey, wrappingKey, algo);
	},

	/**
	 * Wrap a symmetric key with a symmetric key
	 *
	 * @param {CryptoKey} key
	 * @param {CryptoKey} wrappingKey
	 * @return {Promise<ArrayBuffer>}
	 */
	wrapSymmetricKeyWithSymmetricKey: async function (key, wrappingKey) {
		const ivSize = iurio.crypto.constants.symmetricIVByteSize;
		const iv = window.crypto.getRandomValues(new Uint8Array(ivSize));
		const algo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			iv: iv,
			tagLength: iurio.crypto.constants.symmetricTagLength,
		};
		const wrapped = await window.crypto.subtle.wrapKey('raw', key, wrappingKey, algo);
		let output = new Uint8Array(wrapped.byteLength + ivSize);
		output.set(iv, 0);
		output.set(new Uint8Array(wrapped), ivSize);
		return output;
	},

	/**
	 * Wrap a symmetric key with an asymmetric key
	 *
	 * @param {CryptoKey} key
	 * @param {CryptoKey} wrappingPubKey
	 * @return {Promise<ArrayBuffer>}
	 */
	wrapSymmetricKeyWithPublicKey: async function (key, wrappingPubKey) {
		const algo = {
			name: iurio.crypto.constants.keyWrappingAsymmetricAlgorithm,
		};
		return window.crypto.subtle.wrapKey('raw', key, wrappingPubKey, algo);
	},

	/**
	 * Unwrap the private key
	 *
	 * @param {ArrayBuffer} wrappedPrivKey
	 * @param {CryptoKey} unwrappingKey
	 * @return {Promise<ArrayBuffer>}
	 */
	unwrapPrivateKeyWithSymmetricKey: async function (wrappedPrivKey, unwrappingKey) {
		const algo = {
			name: iurio.crypto.constants.keyWrappingSymmetricAlgorithm,
		};
		const keyAlgo = {
			name: iurio.crypto.constants.asymmetricEncryptionAlgorithm,
			hash: iurio.crypto.constants.hashAlgorithm,
		};
		return window.crypto.subtle.unwrapKey('jwk', wrappedPrivKey, unwrappingKey, algo, keyAlgo, true, ['unwrapKey']);
	},

	/**
	 * Unwrap the symmetric key
	 *
	 * @param {ArrayBuffer} wrappedKey
	 * @param {CryptoKey} unwrappingKey
	 * @return {Promise<ArrayBuffer>}
	 */
	unwrapSymmetricKeyWithSymmetricKey: async function (wrappedKey, unwrappingKey) {
		const ivSize = iurio.crypto.constants.symmetricIVByteSize;
		const iv = wrappedKey.slice(0, ivSize);
		const data = wrappedKey.slice(ivSize);
		const algo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			iv: iv,
			tagLength: iurio.crypto.constants.symmetricTagLength,
		};
		const keyAlgo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
		};
		return window.crypto.subtle.unwrapKey('raw', data, unwrappingKey, algo, keyAlgo, true, ['encrypt', 'decrypt']);
	},

	/**
	 * Unwrap the symmetric key
	 *
	 * @param {ArrayBuffer} wrappedKey
	 * @param {CryptoKey} unwrappingPrivKey
	 * @return {Promise<ArrayBuffer>}
	 */
	unwrapSymmetricKeyWithPrivateKey: async function (wrappedKey, unwrappingPrivKey) {
		const algo = {
			name: iurio.crypto.constants.keyWrappingAsymmetricAlgorithm,
		};
		const keyAlgo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
		};
		return window.crypto.subtle.unwrapKey('raw', wrappedKey, unwrappingPrivKey, algo, keyAlgo, true, ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']);
	},

	/**
	 * Perform symmetric encryption
	 *
	 * @param {BufferSource} data
	 * @param {CryptoKey} key
	 * @return {Promise<ArrayBuffer>}
	 */
	encryptSymmetric: async function (data, key) {
		if (data.byteLength === 0) {
			return new ArrayBuffer(0);
		}
		const ivSize = iurio.crypto.constants.symmetricIVByteSize;
		const iv = window.crypto.getRandomValues(new Uint8Array(ivSize));
		const algo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			iv: iv,
			tagLength: iurio.crypto.constants.symmetricTagLength,
		};
		const encrypted = await window.crypto.subtle.encrypt(algo, key, data);
		let output = new Uint8Array(encrypted.byteLength + ivSize);
		output.set(iv, 0);
		output.set(new Uint8Array(encrypted), ivSize);
		return output;
	},

	/**
	 * Perform symmetric decryption
	 *
	 * @param {BufferSource} encrypted
	 * @param {CryptoKey} key
	 * @return {Promise<ArrayBuffer>}
	 */
	decryptSymmetric: async function (encrypted, key) {
		if (encrypted.byteLength === 0) {
			return new ArrayBuffer(0);
		}
		const ivSize = iurio.crypto.constants.symmetricIVByteSize;
		const iv = encrypted.slice(0, ivSize);
		const data = encrypted.slice(ivSize);
		const algo = {
			name: iurio.crypto.constants.symmetricEncryptionAlgorithm,
			iv: iv,
			tagLength: iurio.crypto.constants.symmetricTagLength,
		};
		return window.crypto.subtle.decrypt(algo, key, data);
	},

	/**
	 * Hash the given data
	 *
	 * @param {BufferSource} data
	 * @return {Promise<ArrayBuffer>}
	 */
	hash: async function (data) {
		return window.crypto.subtle.digest(iurio.crypto.constants.hashAlgorithm, data);
	},
};

iurio.crypto.text = {
	/**
	 * Perform symmetric encryption
	 *
	 * @param {string} text
	 * @param {CryptoKey} key
	 * @return {Promise<string>}
	 */
	encryptSymmetric: async function (text, key) {
		const data = iurio.crypto.utils.textEncoder.encode(text);
		const encrypted = await iurio.crypto.primitives.encryptSymmetric(data, key);
		return iurio.crypto.utils.arrayBufferToB64String(encrypted);
	},

	/**
	 * Perform symmetric decryption
	 *
	 * @param {String} encrypted
	 * @param {CryptoKey} key
	 * @return {Promise<string>}
	 */
	decryptSymmetric: async function (encrypted, key) {
		const encryptedData = iurio.crypto.utils.b64StringToArrayBuffer(encrypted);
		const data = await iurio.crypto.primitives.decryptSymmetric(encryptedData, key);
		return iurio.crypto.utils.textDecoder.decode(data);
	},

	/**
	 * Perform symmetric encryption with a key derived from an asymmetric key
	 *
	 * @param {String} text
	 * @param {CryptoKey} key
	 * @return {Promise<string>}
	 */
	encryptSymmetricFromAsymmetric: async function (text, key) {
		const salt = window.crypto.getRandomValues(new Uint8Array(16));
		const symKey = await iurio.crypto.primitives.deriveSymmetricKeyFromAsymmetric(key, salt);
		const encrypted = await this.encryptSymmetric(text, symKey);
		const saltString = iurio.crypto.utils.arrayBufferToB64String(salt).slice(0, 22);  // 16 bytes = 22 significant B64 chars
		return saltString + ':' + encrypted;
	},

	/**
	 * Perform symmetric decryption with a key derived from an asymmetric key
	 *
	 * @param {String} encrypted
	 * @param {CryptoKey} key
	 * @return {Promise<string>}
	 */
	decryptSymmetricFromAsymmetric: async function (encrypted, key) {
		const index = encrypted.indexOf(':');
		const salt = iurio.crypto.utils.b64StringToArrayBuffer(encrypted.slice(0, index));
		const symKey = await iurio.crypto.primitives.deriveSymmetricKeyFromAsymmetric(key, salt);
		return this.decryptSymmetric(encrypted.slice(index + 1), symKey);
	},
};

iurio.crypto.keystore = {
	/**
	 * Store the key pair
	 *
	 * @param {Object} keyPair
	 * @param {CryptoKey} keyPair.privateKey
	 * @param {CryptoKey} keyPair.publicKey
	 */
	storeKeyPair: async function (keyPair) {
		let keyString = await iurio.crypto.primitives.exportKeyJSON(keyPair.privateKey);
		await window.localforage.setItem('privKey', keyString);
		keyString = await iurio.crypto.primitives.exportKeyJSON(keyPair.publicKey);
		await window.localforage.setItem('pubKey', keyString);
	},

	/**
	 * Remove the stored key pair
	 */
	removeKeyPair: async function () {
		await window.localforage.removeItem('privKey');
		await window.localforage.removeItem('pubKey');
	},

	/**
	 * Checks if the session storage contains the private key
	 *
	 * @return {Promise<bool>}
	 */
	existsPrivateKey: async function () {
		return (await window.localforage.getItem('privKey') !== null);
	},

	/**
	 * Load the private key
	 *
	 * @return {Promise<CryptoKey>}
	 */
	loadPrivateKey: async function () {
		const privKeyString = await window.localforage.getItem('privKey');
		if (!privKeyString) {
			throw new Error('No private key stored');
		}
		return iurio.crypto.primitives.importPrivateKeyJSON(privKeyString);
	},

	/**
	 * Load the public key
	 *
	 * @return {Promise<CryptoKey>}
	 */
	loadPublicKey: async function () {
		const pubKeyString = await window.localforage.getItem('pubKey');
		if (!pubKeyString) {
			throw new Error('No public key stored');
		}
		return iurio.crypto.primitives.importPublicKeyJSON(pubKeyString);
	},

	/**
	 * Load the private/public keyPair
	 *
	 * @return {Promise<CryptoKey[]>}
	 */
	loadKeyPair: async function () {
		return Promise.all([this.loadPrivateKey(), this.loadPublicKey()]);
	},

	/**
	 * Store the username
	 *
	 * @param {string} username
	 */
	storeUsername: function (username) {
		window.localStorage.setItem('uname', username);
	},

	/**
	 * Remove the stored username
	 */
	removeUsername: function () {
		window.localStorage.removeItem('uname');
	},

	/**
	 * Load the username
	 *
	 * @return {string}
	 */
	loadUsername: function () {
		return window.localStorage.getItem('uname');
	},
};

iurio.crypto.handler = {
	/**
	 * Authentication preprocessing
	 *
	 * @param {number} authType
	 * @param {Object} authObjectData
	 * @param {Object} api
	 * @param {string} [loginName] needed if not logged in
	 * @return {Promise<Object>} authData
	 */
	beforeAuth: async function (authType, authObjectData, api, loginName) {
		switch (authType) {
			case 1:
			case 2:
				const salt = loginName ?
					await api.entrance.getPasswordSalt(loginName, authType === 2) :
					await api.account.getPasswordSalt(authType === 2);
				const hash = await dcodeIO.bcrypt.hash(authObjectData.password, salt);
				return { password: hash };

			case 10:
				const credentialID = iurio.utils.base64urlToBase64(authObjectData.id);
				const clientDataJSON = iurio.crypto.utils.arrayBufferToString(authObjectData.response.clientDataJSON);
				const authenticatorData = iurio.crypto.utils.arrayBufferToB64String(authObjectData.response.authenticatorData);
				const signature = iurio.crypto.utils.arrayBufferToB64String(authObjectData.response.signature);
				return { credentialID, clientDataJSON, authenticatorData, signature };

			default:
				throw 'Unknown authType: ' + authType;
		}
	},

	/**
	 * Successful login handler
	 *
	 * @param {Object} user
	 * @param {number[]} user.salt
	 * @param {number[]} user.privateKey
	 * @param {number} authType
	 * @param {Object} authObjectData
	 */
	onLogin: async function (user, authType, authObjectData) {
		const salt = iurio.crypto.utils.b64StringToArrayBuffer(user.salt);
		const encryptedPrivKey = iurio.crypto.utils.b64StringToArrayBuffer(user.privateKey);

		const secret = authType === 10 ? authObjectData.secret : authObjectData.password;

		const unwrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(secret, salt);
		const privKey = await iurio.crypto.primitives.unwrapPrivateKeyWithSymmetricKey(encryptedPrivKey, unwrappingKey);
		const pubKey = await iurio.crypto.primitives.importPublicKeyMod(user.publicKey);

		return iurio.crypto.keystore.storeKeyPair({
			privateKey: privKey,
			publicKey: pubKey,
		});
	},

	/**
	 * Get public key credential request options handler
	 *
	 * @param {Object} credentialRequestOptions
	 * @param {string} [disallowedCredentialId]
	 * @return {Object} credentialRequestOptions
	 */
	onStartAuthentication: function (credentialRequestOptions, disallowedCredentialId) {
		credentialRequestOptions.challengeString = credentialRequestOptions.challenge;
		credentialRequestOptions.challenge = iurio.crypto.utils.b64StringToArrayBuffer(credentialRequestOptions.challenge);
		if (disallowedCredentialId) {
			credentialRequestOptions.allowCredentials = credentialRequestOptions.allowCredentials.filter(element => element.id !== disallowedCredentialId);
		}
		credentialRequestOptions.allowCredentials.forEach(element => { element.id = iurio.crypto.utils.b64StringToArrayBuffer(element.id); });
		return credentialRequestOptions;
	},

	/**
	 * Login salt username hashing
	 *
	 * @param {string} username
	 * @return {Promise<string>}
	 */
	beforeGetLoginSalt: async function (username) {
		const usernameBuffer = iurio.crypto.utils.textEncoder.encode(username.toLowerCase());
		const hashBuffer = await iurio.crypto.primitives.hash(usernameBuffer);
		iurio.crypto.keystore.storeUsername(username);
		return iurio.crypto.utils.arrayBufferToHexString(hashBuffer);
	},

	/**
	 * Login username hashing
	 *
	 * @param {string} username
	 * @param {string} salt
	 * @return {Promise<string>}
	 */
	onGetLoginSalt: async function (username, salt) {
		return dcodeIO.bcrypt.hash(username.toLowerCase(), salt);
	},

	/**
	 * Workspace list key decryption
	 *
	 * @param {Object} resData
	 * @param {Object[]} resData.projects
	 * @param {Object[]} resData.workspaces
	 * @return {Promise<Object>} resData
	 */
	onGetBillingData: async function (resData) {
		const privKey = await iurio.crypto.keystore.loadPrivateKey();
		for (let workspace of resData.workspaces) {
			if (!workspace.encryptedSymmetricKey) {
				continue;
			}
			const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(workspace.encryptedSymmetricKey);
			workspace.symmetricKey = await iurio.crypto.primitives.unwrapSymmetricKeyWithPrivateKey(encryptedSymmetricKey, privKey);
			delete workspace.encryptedSymmetricKey;
			workspace.symmetricKeyJWK = await iurio.crypto.primitives.exportKey(workspace.symmetricKey);
			if (workspace.isGeneral) {
				const project = resData.projects.find(project => project.id === workspace.project);
				project.symmetricKey = workspace.symmetricKey;
				delete project.encryptedSymmetricKey;
				project.symmetricKeyJWK = workspace.symmetricKeyJWK;
			} else {
				workspace.name = await iurio.crypto.text.decryptSymmetric(workspace.name, workspace.symmetricKey);
			}
		}
		return resData;
	},

	/**
	 * Workspace list key decryption
	 *
	 * @param {Object} resData
	 * @param {Object[]} resData.projects
	 * @param {Object[]} resData.workspaces
	 * @return {Promise<Object>} resData
	 */
	onGetGeneralData: async function (resData) {
		const privKey = await iurio.crypto.keystore.loadPrivateKey();
		for (let workspace of resData.workspaces) {
			if (!workspace.encryptedSymmetricKey) {
				continue;
			}
			const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(workspace.encryptedSymmetricKey);
			workspace.symmetricKey = await iurio.crypto.primitives.unwrapSymmetricKeyWithPrivateKey(encryptedSymmetricKey, privKey);
			delete workspace.encryptedSymmetricKey;
			workspace.symmetricKeyJWK = await iurio.crypto.primitives.exportKey(workspace.symmetricKey);
			if (workspace.isGeneral) {
				const project = resData.projects.find(project => project.id === workspace.project);
				project.symmetricKey = workspace.symmetricKey;
				delete project.encryptedSymmetricKey;
				project.symmetricKeyJWK = workspace.symmetricKeyJWK;
			} else {
				try {
					workspace.name = await iurio.crypto.text.decryptSymmetric(workspace.name, workspace.symmetricKey);
				} catch (e) {
					console.error('Error decrypting WS name', e);
				}
			}
		}
		if (resData.officeKey.encryptedSymmetricKey) {
			const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(resData.officeKey.encryptedSymmetricKey);
			resData.officeKey.symmetricKey = await iurio.crypto.primitives.unwrapSymmetricKeyWithPrivateKey(encryptedSymmetricKey, privKey);
			delete resData.officeKey.encryptedSymmetricKey;
			resData.officeKey.symmetricKeyJWK = await iurio.crypto.primitives.exportKey(resData.officeKey.symmetricKey);
		}
		if (resData.iurioKey.encryptedSymmetricKey) {
			const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(resData.iurioKey.encryptedSymmetricKey);
			resData.iurioKey.symmetricKey = await iurio.crypto.primitives.unwrapSymmetricKeyWithPrivateKey(encryptedSymmetricKey, privKey);
			delete resData.iurioKey.encryptedSymmetricKey;
			resData.iurioKey.symmetricKeyJWK = await iurio.crypto.primitives.exportKey(resData.iurioKey.symmetricKey);
		}
		return resData;
	},

	/**
	 * Pre-signup key generation
	 *
	 * @param {string[]} emailAddresses
	 * @param {Object[]} allUsers
	 * @param {Object[]} workspaces
	 * @param {Object} [officeKey]
	 * @param {Object} [iurioKey]
	 * @return {Promise<Object[]>} keys
	 */
	beforeInviteUsers: async function (emailAddresses, allUsers, workspaces, officeKey, iurioKey) {
		const privKeys = [];
		const pubKeys = [];
		const keys = [];
		const wrappedWorkspaceKeys = [];
		const officeKeys = [];
		const iurioKeys = [];

		for (let i = 0; i < emailAddresses.length; ++i) {
			const currentEmail = emailAddresses[i];

			const foundUser = allUsers.find(function (user) {
				return user.emailAddress === currentEmail;
			});

			const key = await iurio.crypto.primitives.generateSymmetricWrappingKey();
			const exported = await iurio.crypto.primitives.exportSymmetricKeyString(key);

			let keyPair;
			let wrappedPrivKey;
			if (foundUser) {
				keyPair = {
					privateKey: undefined,
					publicKey: await iurio.crypto.primitives.importPublicKeyMod(foundUser.publicKey),
				};
				wrappedPrivKey = undefined;
			} else {
				try {
					keyPair = await iurio.crypto.primitives.generateKeyPair();
					wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, key);
				} catch (e1) {
					// generated private keys sometimes have a length which is not a multiple of 8 bytes, causing key wrapping to fail
					// For some reason, this loop needs to be unrolled, else all generated keys (in this loop) are invalid.
					// DO NOT TOUCH.
					console.warn(e1);
					try {
						keyPair = await iurio.crypto.primitives.generateKeyPair();
						wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, key);
					} catch (e2) {
						console.warn(e2);
						try {
							keyPair = await iurio.crypto.primitives.generateKeyPair();
							wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, key);
						} catch (e3) {
							console.error('Key generation failed too many times, giving up');
							throw e3;
						}
					}
				}
			}

			const pubKey = await iurio.crypto.primitives.exportPublicKeyMod(keyPair.publicKey);

			if (typeof workspaces !== 'undefined') {
				const wrappedKeys = [];
				for (const workspace of workspaces) {
					const wrappedWorkspaceKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(workspace.symmetricKey, keyPair.publicKey);
					wrappedKeys.push(iurio.crypto.utils.arrayBufferToB64String(wrappedWorkspaceKey));
				}
				wrappedWorkspaceKeys.push(wrappedKeys);
			}

			if (typeof officeKey !== 'undefined') {
				const wrappedOfficeKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(officeKey.symmetricKey, keyPair.publicKey);
				officeKeys.push(iurio.crypto.utils.arrayBufferToB64String(wrappedOfficeKey));
			}
			if (typeof iurioKey !== 'undefined') {
				const wrappedIurioKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(iurioKey.symmetricKey, keyPair.publicKey);
				iurioKeys.push(iurio.crypto.utils.arrayBufferToB64String(wrappedIurioKey));
			}

			privKeys.push(wrappedPrivKey);
			pubKeys.push(pubKey);
			keys.push(exported);
		}
		return {
			privKeys,
			pubKeys,
			keys,
			wrappedWorkspaceKeys,
			officeKeys,
			iurioKeys,
		};
	},

	/**
	 * Pre-login key generation
	 *
	 * @param {string} username
	 * @param {string} backupPassword
	 * @param {(string|ArrayBuffer)} secret
	 * @param {Function} getLoginName
	 * @param {Object[]} systemUsers
	 * @param {number} systemUsers.id
	 * @param {string} systemUsers.publicKey
	 * @param {Object} [credentialResponse]
	 * @param {string} [deviceName]
	 * @return {Promise<Object[]>} [hashedUsername, wrappedPrivKey, salt, keyPair, backupLoginToken, wrappedPrivKeyBackup, backupSalt, pubKey, encryptedOfficeKey, encryptedIurioKey, loginToken | (clientDataJSON, attestation, encryptedDeviceName)]
	 */
	beforeSignupFirstUser: async function (username, backupPassword, secret, getLoginName, systemUsers, credentialResponse, deviceName) {
		const hashedUsernamePromise = getLoginName(username);
		const salt = window.crypto.getRandomValues(new Uint8Array(16));
		const wrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(secret, salt);

		let keyPair;
		let wrappedPrivKey;
		try {
			keyPair = await iurio.crypto.primitives.generateKeyPair();
			wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, wrappingKey);
		} catch (e1) {
			// generated private keys sometimes have a length which is not a multiple of 8 bytes, causing key wrapping to fail
			// For some reason, this loop needs to be unrolled, else all generated keys (in this loop) are invalid.
			// DO NOT TOUCH.
			console.warn(e1);
			try {
				keyPair = await iurio.crypto.primitives.generateKeyPair();
				wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, wrappingKey);
			} catch (e2) {
				console.warn(e2);
				try {
					keyPair = await iurio.crypto.primitives.generateKeyPair();
					wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, wrappingKey);
				} catch (e3) {
					console.error('Key generation failed too many times, giving up');
					throw e3;
				}
			}
		}

		const backupSalt = window.crypto.getRandomValues(new Uint8Array(16));
		const backupWrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(backupPassword, backupSalt);
		const wrappedPrivKeyBackupPromise = iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, backupWrappingKey);
		const backupLoginTokenPromise = dcodeIO.bcrypt.hash(backupPassword, iurio.crypto.constants.bcryptCost);

		const pubKey = await iurio.crypto.primitives.exportPublicKeyMod(keyPair.publicKey);

		const initialOfficeKey = await iurio.crypto.primitives.generateSymmetricKey();
		const wrappedOfficeKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(initialOfficeKey, keyPair.publicKey);
		const wrappedOfficeKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedOfficeKey);
		const initialIurioKey = await iurio.crypto.primitives.generateSymmetricKey();
		const wrappedIurioKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(initialIurioKey, keyPair.publicKey);
		const wrappedIurioKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedIurioKey);
		const iurioKeys = { new: wrappedIurioKeyB64 };
		for (const systemUser of systemUsers) {
			const publicKey = await iurio.crypto.primitives.importPublicKeyMod(systemUser.publicKey);
			const wrappedIurioKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(initialIurioKey, publicKey);
			const wrappedIurioKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedIurioKey);
			iurioKeys[systemUser.id] = wrappedIurioKeyB64;
		}

		if (credentialResponse) {
			const clientDataJSON = iurio.crypto.utils.arrayBufferToString(credentialResponse.clientDataJSON);
			const attestation = iurio.crypto.utils.arrayBufferToB64String(credentialResponse.attestationObject);

			const encryptedDeviceName = deviceName ? iurio.crypto.text.encryptSymmetricFromAsymmetric(deviceName, keyPair.privateKey) : undefined;

			return Promise.all([hashedUsernamePromise, wrappedPrivKey, Array.from(salt), keyPair, backupLoginTokenPromise, wrappedPrivKeyBackupPromise, Array.from(backupSalt), pubKey, wrappedOfficeKeyB64, iurioKeys, clientDataJSON, attestation, encryptedDeviceName]);
		} else {
			const loginTokenPromise = dcodeIO.bcrypt.hash(secret, iurio.crypto.constants.bcryptCost);

			return Promise.all([hashedUsernamePromise, wrappedPrivKey, Array.from(salt), keyPair, backupLoginTokenPromise, wrappedPrivKeyBackupPromise, Array.from(backupSalt), pubKey, wrappedOfficeKeyB64, iurioKeys, loginTokenPromise]);
		}
	},

	/**
	 * Pre-login key generation for system user
	 *
	 * @param {string} username
	 * @param {(string|ArrayBuffer)} secret
	 * @param {Object} [iurioKey]
	 * @param {Function} getLoginName
	 * @return {Promise<Object[]>} [hashedUsername, wrappedPrivKey, salt, pubKey, encryptedIurioKey, loginToken]
	 */
	beforeSystemUserCreation: async function (username, secret, iurioKey, getLoginName) {
		const hashedUsernamePromise = getLoginName(username);
		const salt = window.crypto.getRandomValues(new Uint8Array(16));
		const wrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(secret, salt);

		let keyPair;
		let wrappedPrivKey;
		try {
			keyPair = await iurio.crypto.primitives.generateKeyPair();
			wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, wrappingKey);
		} catch (e1) {
			// generated private keys sometimes have a length which is not a multiple of 8 bytes, causing key wrapping to fail
			// For some reason, this loop needs to be unrolled, else all generated keys (in this loop) are invalid.
			// DO NOT TOUCH.
			console.warn(e1);
			try {
				keyPair = await iurio.crypto.primitives.generateKeyPair();
				wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, wrappingKey);
			} catch (e2) {
				console.warn(e2);
				try {
					keyPair = await iurio.crypto.primitives.generateKeyPair();
					wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, wrappingKey);
				} catch (e3) {
					console.error('Key generation failed too many times, giving up');
					throw e3;
				}
			}
		}

		const pubKey = await iurio.crypto.primitives.exportPublicKeyMod(keyPair.publicKey);

		const wrappedIurioKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(iurioKey.symmetricKey, keyPair.publicKey);
		const wrappedIurioKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedIurioKey);

		const loginTokenPromise = dcodeIO.bcrypt.hash(secret, iurio.crypto.constants.bcryptCost);
		return Promise.all([hashedUsernamePromise, wrappedPrivKey, Array.from(salt), pubKey, wrappedIurioKeyB64, loginTokenPromise, keyPair.privateKey]);
	},

	/**
	 * Pre-login key handling
	 *
	 * @param {string} username
	 * @param {string} wrappedPrivKeyB64
	 * @param {string} pubKeyMod
	 * @param {string} keyString
	 * @param {(string|ArrayBuffer)} secret
	 * @param {Function} getLoginName
	 * @param {Object} [credentialResponse]
	 * @param {string} [deviceName]
	 * @return {Promise<Object[]>} [hashedUsername, wrappedPrivKey, salt, keyPair, (loginToken | clientDataJSON, attestation)]
	 */
	beforeInviteSignup: async function (username, wrappedPrivKeyB64, pubKeyMod, keyString, secret, getLoginName, credentialResponse, deviceName) {
		const hashedUsernamePromise = getLoginName(username);
		const key = await iurio.crypto.primitives.importSymmetricWrappingKeyString(keyString);
		const wrappedPrivKey = iurio.crypto.utils.b64StringToArrayBuffer(wrappedPrivKeyB64);
		const privKey = await iurio.crypto.primitives.unwrapPrivateKeyWithSymmetricKey(wrappedPrivKey, key);
		const pubKey = await iurio.crypto.primitives.importPublicKeyMod(pubKeyMod);
		const keyPair = {
			privateKey: privKey,
			publicKey: pubKey,
		};

		const salt = window.crypto.getRandomValues(new Uint8Array(16));
		const wrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(secret, salt);
		const wrappedPrivKeyPromise = iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(privKey, wrappingKey);

		if (credentialResponse) {
			const clientDataJSON = iurio.crypto.utils.arrayBufferToString(credentialResponse.clientDataJSON);
			const attestation = iurio.crypto.utils.arrayBufferToB64String(credentialResponse.attestationObject);

			const encryptedDeviceName = deviceName ? iurio.crypto.text.encryptSymmetricFromAsymmetric(deviceName, keyPair.privateKey) : undefined;

			return Promise.all([hashedUsernamePromise, wrappedPrivKeyPromise, Array.from(salt), keyPair, clientDataJSON, attestation, encryptedDeviceName]);
		} else {
			const loginTokenPromise = dcodeIO.bcrypt.hash(secret, iurio.crypto.constants.bcryptCost);

			return Promise.all([hashedUsernamePromise, wrappedPrivKeyPromise, Array.from(salt), keyPair, loginTokenPromise]);
		}
	},

	/**
	 * before updating password handler
	 *
	 * @param {string} password
	 * @return {Promise<Object[]>} keys
	 */
	beforeUpdatePassword: async function (password) {
		const privKey = await iurio.crypto.keystore.loadPrivateKey();

		const salt = window.crypto.getRandomValues(new Uint8Array(16));
		const pwWrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(password, salt);
		const wrappedPrivKeyPromise = iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(privKey, pwWrappingKey);

		const loginTokenPromise = dcodeIO.bcrypt.hash(password, iurio.crypto.constants.bcryptCost);

		return Promise.all([loginTokenPromise, wrappedPrivKeyPromise, Array.from(salt)]);
	},

	/**
	 * Successful signup handler
	 *
	 * @param {Object} keyPair
	 * @param {CryptoKey} keyPair.privateKey
	 * @param {CryptoKey} keyPair.publicKey
	 */
	onSignup: async function (keyPair) {
		return iurio.crypto.keystore.storeKeyPair(keyPair);
	},

	/**
	 * Create new key entries for a user
	 *
	 * @param {Object[]} userKeys
	 * @return {Promise<Object>} result (privKey, pukey, key, userKeys)
	 */
	beforeResetUser: async function (userKeys) {
		const key = await iurio.crypto.primitives.generateSymmetricWrappingKey();
		const exported = await iurio.crypto.primitives.exportSymmetricKeyString(key);

		let keyPair;
		let wrappedPrivKey;
		try {
			keyPair = await iurio.crypto.primitives.generateKeyPair();
			wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, key);
		} catch (e1) {
			// generated private keys sometimes have a length which is not a multiple of 8 bytes, causing key wrapping to fail
			// For some reason, this loop needs to be unrolled, else all generated keys (in this loop) are invalid.
			// DO NOT TOUCH.
			console.warn(e1);
			try {
				keyPair = await iurio.crypto.primitives.generateKeyPair();
				wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, key);
			} catch (e2) {
				console.warn(e2);
				try {
					keyPair = await iurio.crypto.primitives.generateKeyPair();
					wrappedPrivKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(keyPair.privateKey, key);
				} catch (e3) {
					console.error('Key generation failed too many times, giving up');
					throw e3;
				}
			}
		}

		const pubKey = await iurio.crypto.primitives.exportPublicKeyMod(keyPair.publicKey);

		for (let currentKey of userKeys) {
			let wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(currentKey.decryptedSymmetricKey, keyPair.publicKey);
			currentKey.encryptedSymmetricKey = iurio.crypto.utils.arrayBufferToB64String(wrappedKey);
			delete currentKey.decryptedSymmetricKey;
		}

		return {
			privKey: wrappedPrivKey,
			pubKey: pubKey,
			key: exported,
			userKeys: userKeys,
		};
	},

	/**
	 * Get public key credential creation options handler
	 *
	 * @param {Object} credentialCreationOptions
	 * @return {Object} credentialCreationOptions
	 */
	onStartRegistration: function (credentialCreationOptions) {
		credentialCreationOptions.challengeString = credentialCreationOptions.challenge;
		credentialCreationOptions.challenge = iurio.crypto.utils.b64StringToArrayBuffer(credentialCreationOptions.challenge);
		credentialCreationOptions.user.id = iurio.crypto.utils.b64StringToArrayBuffer(credentialCreationOptions.user.id);
		return credentialCreationOptions;
	},

	/**
	 * Get authenticators handler
	 *
	 * @param {Array} authenticators
	 * @return {Promise<Array>} authenticators
	 */
	onListAuthenticators: async function (authenticators) {
		let privKey = undefined;
		for (let authenticator of authenticators) {
			if (!authenticator.name) {
				continue;
			}
			if (!privKey) {
				privKey = await iurio.crypto.keystore.loadPrivateKey();
			}
			try {
				authenticator.name = await iurio.crypto.text.decryptSymmetricFromAsymmetric(authenticator.name, privKey);
			} catch (err) {
				console.warn('Error decrypting authenticator name', err);
			}
		}
		return authenticators;
	},

	/**
	 * Token registration handler
	 *
	 * @param {Object} credentialResponse
	 * @param {ArrayBuffer} secret
	 * @param {string} [deviceName]
	 * @return {Promise<Object>} {clientDataJSON, attestation, salt, encryptedPrivateKey}
	 */
	beforeRegisterToken: async function (credentialResponse, secret, deviceName) {
		const clientDataJSON = iurio.crypto.utils.arrayBufferToString(credentialResponse.clientDataJSON);
		const attestation = iurio.crypto.utils.arrayBufferToB64String(credentialResponse.attestationObject);

		const salt = window.crypto.getRandomValues(new Uint8Array(16));
		const wrappingKey = await iurio.crypto.primitives.deriveSymmetricWrappingKey(secret, salt);
		const privKey = await iurio.crypto.keystore.loadPrivateKey();
		const encryptedPrivateKey = await iurio.crypto.primitives.wrapPrivateKeyWithSymmetricKey(privKey, wrappingKey);

		const result = {
			clientDataJSON,
			attestation,
			salt: Array.from(salt),
			encryptedPrivateKey,
		};

		if (deviceName) {
			result.encryptedDeviceName = await iurio.crypto.text.encryptSymmetricFromAsymmetric(deviceName, privKey);
		}

		return result;
	},

	/**
	 * Token renaming handler
	 *
	 * @param {string} deviceName
	 * @return {Promise<string>}
	 */
	beforeRenameToken: async function (deviceName) {
		const privKey = await iurio.crypto.keystore.loadPrivateKey();
		return iurio.crypto.text.encryptSymmetricFromAsymmetric(deviceName, privKey);
	},

	/**
	 * Wrap given keys for user
	 *
	 * @param {Object[]} userKeys
	 * @param {Object} user
	 * @return {Promise<Object[]>} userKeys
	 */
	beforePromoteToSysAdmin: async function (userKeys, user) {
		const pubKey = await iurio.crypto.primitives.importPublicKeyMod(user.publicKey);

		for (let currentKey of userKeys) {
			let wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(currentKey.decryptedSymmetricKey, pubKey);
			currentKey.encryptedSymmetricKey = iurio.crypto.utils.arrayBufferToB64String(wrappedKey);
		}
		return userKeys;
	},

	/**
	 * Workspace key generation before adding project
	 *
	 * @param {Object[]} sysAdmins
	 * @param {number} sysAdmins.id
	 * @param {string} sysAdmins.publicKey
	 * @return {Promise<Object>} result (key, wrappedKey, sysAdminKeys)
	 */
	beforeProjectAdd: async function (sysAdmins) {
		const key = await iurio.crypto.primitives.generateSymmetricKey();
		const pubKey = await iurio.crypto.keystore.loadPublicKey();
		const wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(key, pubKey);

		let sysAdminKeys = [];
		for (let currentSysAdmin of sysAdmins) {
			const pubKey = await iurio.crypto.primitives.importPublicKeyMod(currentSysAdmin.publicKey);
			const wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(key, pubKey);
			const wrappedKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedKey);
			sysAdminKeys.push({
				id: currentSysAdmin.id,
				wrappedKey: wrappedKeyB64,
			});
		}

		return {
			key: key,
			wrappedKey: iurio.crypto.utils.arrayBufferToB64String(wrappedKey),
			sysAdminKeys: sysAdminKeys,
		};
	},

	/**
	 * Add key after adding project
	 *
	 * @param {Object} project
	 * @param {CryptoKey} key
	 * @return {Object} project
	 */
	onProjectAdd: async function (project, key) {
		project.symmetricKey = key;
		if (project.workspaces) {
			const generalWorkspace = project.workspaces.find(function (workspace) { return workspace.isGeneral; });
			generalWorkspace.symmetricKey = key;
		}
		return project;
	},

	/**
	 * Generate user workspace keys before adding participants
	 *
	 * @param {CryptoKey} generalWorkspaceKey
	 * @param {Object[]} users
	 * @param {string} users.publicKey
	 * @return {Promise<string[]>} user keys
	 */
	beforeProjectParticipantAdd: async function (generalWorkspaceKey, users) {
		let wrappedKeys = [];
		for (let user of users) {
			const pubKey = await iurio.crypto.primitives.importPublicKeyMod(user.publicKey);
			const wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(generalWorkspaceKey, pubKey);
			wrappedKeys.push(iurio.crypto.utils.arrayBufferToB64String(wrappedKey));
		}
		return wrappedKeys;
	},

	/**
	 * Workspace key generation
	 *
	 * @param {string} name
	 * @param {Object[]} sysAdmins
	 * @param {number} sysAdmins.id
	 * @param {string} sysAdmins.publicKey
	 * @param {Object} [templateKey]
	 * @param {number} templateKey.id
	 * @param {CryptoKey} templateKey.symmetricKey
	 * @return {Promise<Object>} encrypted name, key, base64-encoded encrypted key and sysAdmin keys
	 */
	beforeWorkspaceAdd: async function (name, sysAdmins, templateKey) {
		const key = templateKey ? templateKey.symmetricKey : await iurio.crypto.primitives.generateSymmetricKey();
		const pubKey = await iurio.crypto.keystore.loadPublicKey();
		const wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(key, pubKey);
		const wrappedKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedKey);
		const encryptedName = await iurio.crypto.text.encryptSymmetric(name, key);

		let sysAdminKeys = [];
		for (let currentSysAdmin of sysAdmins) {
			const pubKey = await iurio.crypto.primitives.importPublicKeyMod(currentSysAdmin.publicKey);
			const wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(key, pubKey);
			const wrappedKeyB64 = iurio.crypto.utils.arrayBufferToB64String(wrappedKey);
			sysAdminKeys.push({
				id: currentSysAdmin.id,
				wrappedKey: wrappedKeyB64,
			});
		}

		return {
			name: encryptedName,
			key,
			wrappedKey: wrappedKeyB64,
			sysAdminKeys,
		};
	},

	/**
	 * Store workspace key after adding workspace
	 *
	 * @param {Object} workspace
	 * @param {CryptoKey} key
	 * @return {Object} workspace
	 */
	onWorkspaceAdd: async function (workspace, key) {
		workspace.symmetricKey = key;
		return workspace;
	},

	/**
	 * Generate user workspace keys before adding participants
	 *
	 * @param {CryptoKey} workspaceKey
	 * @param {CryptoKey} generalWorkspaceKey
	 * @param {Object[]} users
	 * @param {string} users.publicKey
	 * @param {boolean} users.isExternal
	 * @return {Promise<Object[]>} result (wrappedKeys, wrappedGeneralKeys)
	 */
	beforeWorkspaceParticipantAdd: async function (workspaceKey, generalWorkspaceKey, users) {
		let wrappedKeys = [];
		let wrappedGeneralKeys = [];
		for (let user of users) {
			const pubKey = await iurio.crypto.primitives.importPublicKeyMod(user.publicKey);
			const wrappedKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(workspaceKey, pubKey);
			wrappedKeys.push(iurio.crypto.utils.arrayBufferToB64String(wrappedKey));
			if (!user.isExternal) {
				const wrappedGeneralKey = await iurio.crypto.primitives.wrapSymmetricKeyWithPublicKey(generalWorkspaceKey, pubKey);
				wrappedGeneralKeys.push(iurio.crypto.utils.arrayBufferToB64String(wrappedGeneralKey));
			} else {
				wrappedGeneralKeys.push(null);
			}
		}
		return {
			wrappedKeys: wrappedKeys,
			wrappedGeneralKeys: wrappedGeneralKeys,
		};
	},

	/**
	 * Decrypt channel message list result
	 *
	 * @param {string} message
	 * @param {CryptoKey} symmetricKey
	 * @return {Promise<string>} encrypted message
	 */
	beforeSendChannelMessage: async function (message, symmetricKey) {
		return iurio.crypto.text.encryptSymmetric(message, symmetricKey);
	},

	/**
	 * Decrypt channel message list result
	 *
	 * @param {Object} resData
	 * @param {CryptoKey} symmetricKey
	 * @return {Promise<Object>} resData
	 */
	onListChannelMessages: async function (resData, symmetricKey) {
		for (let message of resData.messages) {
			message.message = await iurio.crypto.text.decryptSymmetric(message.message, symmetricKey);
		}
		return resData;
	},

	/**
	 * Decrypt task attachments result
	 *
	 * @param {Object} resData
	 * @param {CryptoKey} symmetricKey
	 * @return {Promise<Object>} resData
	 */
	onListTaskAttachments: async function (resData, symmetricKey) {
		for (let attachment of resData) {
			attachment.url = await iurio.crypto.text.decryptSymmetric(attachment.url, symmetricKey);
		}
		return resData;
	},

	/**
	 * Decrypt task comments result
	 *
	 * @param {Object} resData
	 * @param {CryptoKey} symmetricKey
	 * @return {Promise<Object>} resData
	 */
	onListTaskComments: async function (resData, symmetricKey) {
		for (let comment of resData) {
			comment.comment = await iurio.crypto.text.decryptSymmetric(comment.comment, symmetricKey);
		}
		return resData;
	},

	/**
	 * Decrypt task comments and attachments result
	 *
	 * @param {Object} resData
	 * @param {CryptoKey} symmetricKey
	 * @return {Promise<Object>} resData
	 */
	onListTaskCommentsAndAttachments: async function (resData, symmetricKey) {
		await Promise.all([
			this.onListTaskComments(resData.taskComments, symmetricKey),
			this.onListTaskAttachments(resData.taskAttachments, symmetricKey),
		]);
		return resData;
	},

	/**
	 * Convert own user keys
	 *
	 * @param {Object[]} userKeys
	 * @return {Promise<Object[]>} userKeys
	 */
	onListAllKeys: async function (userKeys) {
		const privKey = await iurio.crypto.keystore.loadPrivateKey();

		for (let currentKey of userKeys) {
			let encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(currentKey.encryptedSymmetricKey);
			currentKey.decryptedSymmetricKey = await iurio.crypto.primitives.unwrapSymmetricKeyWithPrivateKey(encryptedSymmetricKey, privKey);
		}
		return userKeys;
	},

	/**
	 * Convert other user keys
	 *
	 * @param {Object[]} userKeys
	 * @return {Promise<Object[]>} userKeys
	 */
	onListAllUserKeys: async function (userKeys) {
		for (let currentKey of userKeys) {
			delete currentKey.encryptedSymmetricKey;
		}
		return userKeys;
	},

	/**
	 * Reminder list processing
	 *
	 * @param {Object} resData
	 * @param {Object[]} resData
	 * @return {Promise<Object>} resData
	 */
	onListWorkspaceReminder: async function (resData) {
		for (const reminder of resData) {
			reminder.dueDate = new Date(reminder.dueDate);
			if (reminder.recurrenceEnds) {
				reminder.recurrenceEnds = new Date(reminder.recurrenceEnds);
			}
		}

		return resData;
	},

	/**
	 * Approval list processing
	 *
	 * @param {Object} resData
	 * @param {Object[]} resData
	 * @param {Object} workspace
	 * @return {Promise<Object>} resData
	 */
	onListWorkspaceApproval: async function (resData, workspace) {
		if (!workspace.symmetricKey) {
			return;
		}

		for (let approval of resData.approvals) {
			approval.comment = await iurio.crypto.text.decryptSymmetric(approval.comment, workspace.symmetricKey);
		}

		for (let vote of resData.votes) {
			vote.comment = await iurio.crypto.text.decryptSymmetric(vote.comment, workspace.symmetricKey);
		}

		return resData;
	},

	/**
	 * Datasafe content processing
	 *
	 * @param {Object[]} syncList
	 * @param {Object[]} syncList[].files
	 * @param {String} syncList[].files.name name encrypted with workspace key
	 * @param {Object[]} workspaces
	 * @return {Promise<Object[]>} syncList
	 */
	onListSyncFiles: async function (syncList, workspaces) {
		for (const sync of syncList) {
			if (sync.files.length === 0) {
				continue;
			}
			const foundWorkspace = workspaces.find(workspace => workspace.id === sync.files[0].workspace);
			if (!foundWorkspace) {
				console.warn('Did not find workspace for', sync.files[0]);
				continue;
			}

			for (const file of sync.files) {
				file.name = await iurio.crypto.text.decryptSymmetric(file.name, foundWorkspace.symmetricKey);
			}
		}
		return syncList;
	},

	/**
	 * Datasafe content processing
	 *
	 * @param {Object} resData
	 * @param {Object[]} resData.folders
	 * @param {Object} workspace
	 * @return {Promise<Object>} resData
	 */
	onListDatasafeContents: async function (resData, workspace) {
		if (!workspace.symmetricKey) {
			return;
		}
		let rootFolderID;
		for (let folder of resData.folders) {
			if (folder.parentFolderID === null) {
				folder.name = workspace.name;
				rootFolderID = folder.id;
			} else {
				folder.name = await iurio.crypto.text.decryptSymmetric(folder.name, workspace.symmetricKey);
			}
			for (let file of folder.files) {
				file.name = await iurio.crypto.text.decryptSymmetric(file.name, workspace.symmetricKey);
				file.name = iurio.utils.toOSValidFileName(file.name);
				if (file.description) {
					file.description = await iurio.crypto.text.decryptSymmetric(file.description, workspace.symmetricKey);
				}
			}
		}
		for (let folder of resData.deletedFolders) {
			if (folder.parentFolderID === rootFolderID) {
				folder.name = workspace.name;
			} else {
				folder.name = await iurio.crypto.text.decryptSymmetric(folder.name, workspace.symmetricKey);
			}
			for (let file of folder.files) {
				file.name = await iurio.crypto.text.decryptSymmetric(file.name, workspace.symmetricKey);
				file.name = iurio.utils.toOSValidFileName(file.name);
				if (file.description) {
					file.description = await iurio.crypto.text.decryptSymmetric(file.description, workspace.symmetricKey);
				}
			}
		}
		return resData;
	},

	/**
	 * Datasafe file version copy key handling
	 *
	 * @param {Object} targetWorkspace
	 * @param {Object} version
	 * @param {Object} versionWorkspace
	 * @return {Promise<string>} reencrypted file key (if different workspace)
	 */
	beforeCopyDatasafeFileVersion: async function (targetWorkspace, version, versionWorkspace) {
		if (targetWorkspace.id === version.workspace) {
			return;
		}

		if (!versionWorkspace || version.workspace !== versionWorkspace.id) {
			throw 'Invalid versionWorkspace';
		}

		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(version.encryptedSymmetricKey);
		const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, versionWorkspace.symmetricKey);
		const reencryptedKey = iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.wrapSymmetricKeyWithSymmetricKey(key, targetWorkspace.symmetricKey));

		return reencryptedKey;
	},

	/**
	 * Datasafe file version export handling
	 *
	 * @param {string} name
	 * @param {Object} targetWorkspace
	 * @param {Object} version
	 * @param {Object} versionWorkspace
	 * @return {Promise<Object>} encrypted name and reencrypted file key (if different workspace)
	 */
	beforeExportDatasafeFileVersion: async function (name, targetWorkspace, version, versionWorkspace) {
		const result = {
			name: await this.encryptName(name, targetWorkspace),
		};
		if (targetWorkspace.id === version.workspace) {
			return result;
		}

		if (!versionWorkspace || version.workspace !== versionWorkspace.id) {
			throw 'Invalid versionWorkspace';
		}

		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(version.encryptedSymmetricKey);
		const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, versionWorkspace.symmetricKey);
		const reencryptedKey = iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.wrapSymmetricKeyWithSymmetricKey(key, targetWorkspace.symmetricKey));

		result.encryptedSymmetricKey = reencryptedKey;
		return result;
	},
	/**
	 * Datasafe file history content processing
	 *
	 * @param {Object} resData
	 * @param {Object} resData.file
	 * @param {Object[]} resData.history
	 * @param {Object[]} workspaces
	 * @return {Promise<Object>} resData
	 */
	onListDatasafeFileHistory: async function (resData, workspaces) {
		const fileWorkspace = workspaces.find(workspace => workspace.id === resData.file.workspace);
		resData.file.name = await this.decryptName(resData.file.name, fileWorkspace);
		resData.file.name = iurio.utils.toOSValidFileName(resData.file.name);
		if (resData.file.description) {
			resData.file.description = await this.decryptName(resData.file.description, fileWorkspace);
		}
		for (let version of resData.history) {
			const versionWorkspace = workspaces.find(workspace => workspace.id === version.workspace);
			if (version.description) {
				version.description = await this.decryptName(version.description, versionWorkspace);
			}
			for (let comment of version.comments) {
				comment.comment = await this.decryptName(comment.comment, versionWorkspace);
			}
		}
		return resData;
	},

	/**
	 * File encryption
	 *
	 * @param {File} file
	 * @param {Object} workspace
	 * @param {Object} [fileToUpdate]
	 * @return {Promise<File>} encrypted file
	 */
	beforeUpload: async function (file, workspace, fileToUpdate) {
		if (!file.arrayBuffer) {
			file.arrayBuffer = this.helpers.arrayBufferReader(file);
		}
		const key = fileToUpdate ?
			await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(iurio.crypto.utils.b64StringToArrayBuffer(fileToUpdate.encryptedSymmetricKey), workspace.symmetricKey) :
			await iurio.crypto.primitives.generateSymmetricKey();
		let data;
		try {
			data = await file.arrayBuffer();
		} catch (unusedErr) {
			data = await this.helpers.arrayBufferReader(file)();
		}

		const encryptedData = await iurio.crypto.primitives.encryptSymmetric(data, key);
		let encryptedFile = new File([encryptedData], file.name);
		const ignoreProperties = ['size', 'arrayBuffer', 'slice', 'stream', 'text'];
		for (let property in file) {
			if (ignoreProperties.includes(property)) {
				continue;
			}
			encryptedFile[property] = file[property];
		}
		if (!encryptedFile.upload) {
			encryptedFile.upload = {};
		}
		encryptedFile.upload.symmetricKey = fileToUpdate ?
			fileToUpdate.encryptedSymmetricKey :
			iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.wrapSymmetricKeyWithSymmetricKey(key, workspace.symmetricKey));
		if (file.renamedFileName) {
			encryptedFile.upload.originalFilename = file.renamedFileName;
			encryptedFile.upload.filename = await this.encryptName(file.renamedFileName, workspace);
		} else {
			encryptedFile.upload.originalFilename = file.name;
			encryptedFile.upload.filename = await this.encryptName(file.name, workspace);
		}
		encryptedFile.upload.originalSize = file.size;

		if (file.versionDescription) {
			encryptedFile.upload.versionDescription = await this.encryptName(file.versionDescription, workspace);
		}

		if (fileToUpdate) {
			encryptedFile.upload.hash = iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.hash(data));
		}

		return encryptedFile;
	},

	/**
	 * File decryption
	 *
	 * @param {Blob} data
	 * @param {Object} workspace
	 * @param {Object} file
	 * @param {string} mimeType
	 * @return {Promise<File>} decrypted file
	 */
	onDownload: async function (data, workspace, file) {
		if (!data.arrayBuffer) {
			data.arrayBuffer = this.helpers.arrayBufferReader(data);
		}
		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(file.encryptedSymmetricKey);
		const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, workspace.symmetricKey);
		let encrypted;
		try {
			encrypted = await data.arrayBuffer();
		} catch (unusedErr) {
			encrypted = await this.helpers.arrayBufferReader(data)();
		}
		const decrypted = await iurio.crypto.primitives.decryptSymmetric(encrypted, key);
		const meta = {
			type: this.helpers.getMimeType(file.name),
		};
		if (file.lastModified) {
			meta.lastModified = file.lastModified;
		}
		return new File([decrypted], file.name, meta);
	},

	/**
	 * File name/key re-encryption
	 *
	 * @param {Object} file
	 * @param {Object} sourceWorkspace
	 * @param {Object} targetWorkspace
	 * @return {Promise<File>} encrypted file
	 */
	beforeCopyMoveFile: async function (file, sourceWorkspace, targetWorkspace) {
		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(file.encryptedSymmetricKey);
		const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, sourceWorkspace.symmetricKey);
		const reencryptedKey = iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.wrapSymmetricKeyWithSymmetricKey(key, targetWorkspace.symmetricKey));
		const name = await iurio.crypto.text.encryptSymmetric(file.name, targetWorkspace.symmetricKey);
		return {
			name: name,
			encryptedSymmetricKey: reencryptedKey,
		};
	},

	/**
	 * File name/key re-encryption
	 *
	 * @param {Object} copyContent
	 * @param {Object} sourceWorkspace
	 * @param {Object} [targetWorkspace]
	 * @return {Promise<Object>} encrypted content
	 */
	beforeCopyMoveFiles: async function (copyContent, sourceWorkspace, targetWorkspace) {
		const differentWorkspace = targetWorkspace && targetWorkspace.id !== sourceWorkspace.id;
		const processFolder = async function (folder) {
			const resultFolder = {
				id: folder.id,
				files: [],
				subFolders: [],
			};
			if (folder.name && differentWorkspace) {
				resultFolder.name = await iurio.crypto.text.encryptSymmetric(folder.name, targetWorkspace.symmetricKey);
			}
			if (folder.newName && targetWorkspace) {
				resultFolder.newName = await iurio.crypto.text.encryptSymmetric(folder.newName, targetWorkspace.symmetricKey);
			}
			for (let file of folder.files) {
				const resultFile = {
					id: file.id,
				};
				if (differentWorkspace) {
					const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(file.encryptedSymmetricKey);
					const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, sourceWorkspace.symmetricKey);
					resultFile.encryptedSymmetricKey = iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.wrapSymmetricKeyWithSymmetricKey(key, targetWorkspace.symmetricKey));
					resultFile.name = await iurio.crypto.text.encryptSymmetric(file.name, targetWorkspace.symmetricKey);
					if (file.description) {
						resultFile.description = await iurio.crypto.text.encryptSymmetric(file.description, targetWorkspace.symmetricKey);
					}
				} else {
					resultFile.encryptedSymmetricKey = file.encryptedSymmetricKey;
				}
				resultFolder.files.push(resultFile);
			}
			for (let subFolder of folder.subFolders) {
				resultFolder.subFolders.push(await processFolder(subFolder));
			}
			return resultFolder;
		};
		return processFolder(copyContent);
	},

	/**
	 * File key decryption
	 *
	 * @param {Object} workspace
	 * @param {Object} file
	 * @return {Promise<string>} key
	 */
	beforeCreateWopiSession: async function (workspace, file) {
		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(file.encryptedSymmetricKey);
		const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, workspace.symmetricKey);
		return iurio.crypto.primitives.exportSymmetricKeyString(key);
	},

	/**
	 * File key decryption
	 *
	 * @param {Object} workspace
	 * @param {Object} file
	 * @param {string} signedFileName
	 * @return {Promise<Object[]>} [key, signedKey, encryptedSignedKey, signedFileName]
	 */
	beforeInitFileSign: async function (workspace, file, signedFileName) {
		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(file.encryptedSymmetricKey);
		const key = await iurio.crypto.primitives.unwrapSymmetricKeyWithSymmetricKey(encryptedSymmetricKey, workspace.symmetricKey);
		const keyPromise = iurio.crypto.primitives.exportSymmetricKeyString(key);
		const signedKey = await iurio.crypto.primitives.generateSymmetricKey();
		const signedKeyPromise = iurio.crypto.primitives.exportSymmetricKeyString(signedKey);
		const encryptedSignedKey = iurio.crypto.utils.arrayBufferToB64String(await iurio.crypto.primitives.wrapSymmetricKeyWithSymmetricKey(signedKey, workspace.symmetricKey));
		const signedFileNamePromise = this.encryptName(signedFileName, workspace);
		return Promise.all([keyPromise, signedKeyPromise, encryptedSignedKey, signedFileNamePromise]);
	},

	/**
	 * List Tasks with attachment data processing
	 *
	 * @param {Object} resData
	 * @param {Object} workspace
	 * @return {Promise<Object>} resData
	 */
	onListTasksWithAttachment: async function (resData, workspace) {
		for (let task of resData.tasks) {
			task.taskText = await iurio.crypto.text.decryptSymmetric(task.taskText, workspace.symmetricKey);
		}
		for (let attachment of resData.taskAttachments) {
			attachment.url = await iurio.crypto.text.decryptSymmetric(attachment.url, workspace.symmetricKey);
		}
		return resData;
	},

	/**
	 * List My Tasks
	 *
	 * @param {Object} resData
	 * @param {Object} workspace
	 * @return {Promise<Object>} resData
	 */
	 onListMyTasks: async function (resData, workspaces) {
		for (let task of resData) {
			let workspace = workspaces.find(w => w.id === task.lane.workspace);
			if (!workspace) {
				console.warn('workspace with id ' + task.lane.workspace + ' not found');
				continue;
			}
			task.taskText = await iurio.crypto.text.decryptSymmetric(task.taskText, workspace.symmetricKey);
		}
		return resData;
	},

	/**
	 * Board data processing
	 *
	 * @param {Object} resData
	 * @param {Object} workspace
	 * @return {Promise<Object>} resData
	 */
	onGetBoardData: async function (resData, workspace) {
		for (let lane of resData.lanes) {
			for (let task of lane.tasks) {
				try {
					task.taskText = await iurio.crypto.text.decryptSymmetric(task.taskText, workspace.symmetricKey);
				} catch (e) {
					console.error('Error decrypting task text:', e);
				}
			}
		}
		return resData;
	},

	/**
	 * Tags list processing
	 *
	 * @param {Object[]} tags
	 * @param {Object} workspace
	 * @return {Promise<Object[]>} resData
	 */
	onListTags: async function (tags, workspace) {
		for (let tag of tags) {
			tag.name = await iurio.crypto.text.decryptSymmetric(tag.name, workspace.symmetricKey);
		}
		return tags;
	},

	/**
	 * Workspace key decryption
	 *
	 * @param {Object} workspace
	 */
	getWorkspaceKey: async function (workspace) {
		if (!workspace.encryptedSymmetricKey) {
			return;
		}
		const privKey = await iurio.crypto.keystore.loadPrivateKey();
		const encryptedSymmetricKey = iurio.crypto.utils.b64StringToArrayBuffer(workspace.encryptedSymmetricKey);
		workspace.symmetricKey = await iurio.crypto.primitives.unwrapSymmetricKeyWithPrivateKey(encryptedSymmetricKey, privKey);
	},

	/**
	 * Name encryption
	 *
	 * @param {string} name
	 * @param {Object} workspace
	 * @return {Promise<String>} encrypted name
	 */
	encryptName: async function (name, workspace) {
		if (!workspace.symmetricKey) {
			return name;
		}
		return iurio.crypto.text.encryptSymmetric(name, workspace.symmetricKey);
	},

	/**
	 * Name decryption
	 *
	 * @param {string} encryptedName
	 * @param {Object} workspace
	 * @return {Promise<String>} encrypted name
	 */
	decryptName: async function (encryptedName, workspace) {
		if (!workspace.symmetricKey) {
			return encryptedName;
		}
		return iurio.crypto.text.decryptSymmetric(encryptedName, workspace.symmetricKey);
	},

	helpers: {
		getMimeType: function (fileName) {
			switch (fileName.substring(fileName.length - 4).toLowerCase()) {
				case '.pdf':
					return 'application/pdf';

				case '.png':
					return 'image/png';

				case '.jpg':
				case '.jpe':
				case 'jpeg':
					return 'image/jpeg';

				case '.gif':
					return 'image/gif';

				case '.bmp':
					return 'image/bmp';

				case '.tif':
				case 'tiff':
					return 'image/tiff';

				case '.ico':
					return 'image/x-icon';

				case '.txt':
				case '.csv':
					return 'text/plain';

				case '.xml':
					return 'text/xml';

				case '.wav':
					return 'audio/wav';

				case '.mp3':
					return 'audio/mpeg';

				case '.ogg':
					return 'audio/ogg';

				case '.mpg':
				case '.mpe':
				case 'mpeg':
					return 'video/mpeg';

				case '.mp4':
					return 'video/mp4';

				case '.mov':
					return 'video/quicktime';

				case '.avi':
					return 'video/x-msvideo';

				case 'webm':
					return 'video/webm';

				default:
					return 'application/octet-stream';
			}
		},

		arrayBufferReader: function (blob) {
			return function () {
				return new Promise(function (resolve, reject) {
					const fr = new FileReader();
					fr.onload = function () {
						resolve(fr.result);
					};
					fr.onerror = function () {
						reject(fr.error);
					};
					fr.readAsArrayBuffer(blob);
				});
			};
		},
	},
};

if (typeof module !== 'undefined') {
	module.exports = iurio.crypto;
}
