var iurio = iurio || {}; //eslint-disable-line

iurio.constants = {
	defaultDateOptions: {
		year: 'numeric',
		month: 'numeric',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	},

	defaultDateOnlyOptions: {
		year: 'numeric',
		month: 'numeric',
		day: '2-digit',
	},

	defaultTimeOptions: {
		hour: 'numeric',
		minute: '2-digit',
	},

	roleNames: {
		admin: 'Projekt-Administrator',
		member: 'Mitglied',
		guest: 'Gast',
	},
};
